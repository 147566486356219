import { createRouter, createWebHashHistory } from 'vue-router'
import { SpeedyLogger } from '@/classes';

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        {
            name: 'search',
            path: '/search/:searchText?',
            component: () => import('@/components/PersonLocationList.vue'),
            alias: '/',
            props: true
        },
        {
            name: 'teacher',
            path: '/teacher/:teacherCode',
            component: () => import('@/components/TeacherView.vue'),
            props: true
        },
        {
            name: 'student',
            path: '/student/:studentID',
            component: () => import('@/components/StudentView.vue'),
            props: true
        },
        {
            name: 'user',
            path: '/user/:userID',
            component: () => import('@/components/UserView.vue'),
            props: true
        },
        {
            name: 'subject',
            path: '/subject/:code',
            component: () => import('@/components/SubjectView.vue'),
            props: true
        },
        {
            name: 'room',
            path: '/room/:code',
            component: () => import('@/components/RoomView.vue'),
            props: true
        },
        {
            name: 'yard-duty-location',
            path: '/yard-duty-location/:ClassDescription',
            component: () => import('@/components/YardDutyLocationView.vue'),
            props: true
        },
        {
            name: 'compare-timetable',
            path: '/compare/:info',
            component: () => import('@/components/CompareTimetable.vue'),
            props: true
        },
        {
            name: 'who-is-free',
            path: '/who-is-free',
            component: () => import('@/components/WhoIsFreeTimetable.vue'),
            props: true
        },
        {
            name: 'master-timetable',
            path: '/master-timetable',
            component: () => import('@/components/MasterTimetable.vue'),
            props: true
        },
        {
            name: 'master-room-timetable',
            path: '/master-room-timetable',
            component: () => import('@/components/MasterRoomTimetable.vue'),
            props: true
        },
        {
            name: 'find-room',
            path: '/find-room/:subjectID',
            component: () => import('@/components/FindRoom.vue'),
            props: true
        },
        {
            name: 'login',
            path: '/login',
            component: () => import('@/components/Login.vue'),
            props: true
        },
        {
            name: 'student-list',
            path: '/student-list',
            component: () => import('@/components/StudentList.vue'),
            beforeEnter: (to, from, next) => {
                var ids = to.params.ids;
                if (!ids) {
                    if (from.name == 'login')
                        next('/');
                    else
                        next(false);
                } else
                    next();
            },
            props: true
        },
        {
            name: 'historical-timetables',
            path: '/historical-timetables',
            component: () => import('@/components/HistoricalTimetables.vue'),
            props: true
        }
    ]
})

export default router

import globals from '@/globals';

router.beforeEach((to, from, next) => {
    console.log('to', to.path);
    console.trace()
    SpeedyLogger.log(to.path);
    document.title = 'Speedy';
    if (!globals.loaded && to.name !== 'login') {
        globals.LOGIN_REDIRECT = to.path;
        next('/login');
    } else
        next();
})