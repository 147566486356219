import moment from 'moment';
import { downloadLatestTimetable, downloadTodayPinkiesSharepoint } from '@/ms_graph_api.js';
import config from '@/config.js';
import loadEverything from './loadEverything';
import { reactive } from 'vue';

const FileFetcher = reactive({
    timetable_data: null,
    lastModifiedDateTime: null,
});

FileFetcher.ready = new Promise((resolve, reject) => {
    FileFetcher.readyResolve = resolve;
});

FileFetcher.saveState = function () {
    localStorage.speedy = JSON.stringify({ timetable_data: this.timetable_data, lastModifiedDateTime: this.lastModifiedDateTime, version: config.SPEEDY_VERSION });
}

FileFetcher.loadState = function () {
    try {
        if (!localStorage.speedy)
            return;
        var cached = JSON.parse(localStorage.speedy);
        this.timetable_data = cached.timetable_data;
        if (cached.version != config.SPEEDY_VERSION) {
            console.warn('Cannot use cached data with version ' + localStorage["SPEEDY_VERSION"] + ' when this script is version ' + config.SPEEDY_VERSION);
            setTimeout(this.refresh.bind(this), 1);
            return;
        }
        this.lastModifiedDateTime = cached.lastModifiedDateTime;
        console.log(`Loaded cached timetable from ${this.lastModifiedDateTime}`);

        var lastModifiedDateTime = moment(this.lastModifiedDateTime);
        var diff = moment().diff(lastModifiedDateTime, 'minutes', true);
        this.readyResolve();
        if (diff > 5) { //if more than 5 minutes since last refresh, then download it
            console.log('Timetable is stale. Scheduling refresh.');
            setTimeout(() => this.refresh(), 1);
        }
        loadEverything(this.timetable_data);
    } catch (e) {
        console.error('Could not loadState', e);
    }
}

FileFetcher.refresh = async function () {
    try {
        var latest_timetable_data = await downloadLatestTimetable();
        if (localStorage['substitutions']) {
            downloadTodayPinkiesSharepoint();
        }
    } catch (e) {
        console.error('Error on downloadLatestTimetable:', e);
        app.error = 'Could not get timetable data. Click here to try logging in again.';
        throw e;
    }
    localStorage['SPEEDY_VERSION'] = config.SPEEDY_VERSION;

    this.timetable_data = latest_timetable_data.timetable_data;

    this.lastModifiedDateTime = latest_timetable_data.lastModifiedDateTime;
    this.saveState();
    this.readyResolve();
    loadEverything(this.timetable_data);
}

FileFetcher.loadState();
window.FileFetcher = FileFetcher;
export default FileFetcher;