import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment';
import '@/assets/whereisstudent.css';

window.checkboxData = {}
if (localStorage['checkboxData']) {
    window.checkboxData = JSON.parse(localStorage['checkboxData'])
}
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
})

const app = createApp(App)

app.use(router)

app.mount('#app')
