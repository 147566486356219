<template>
<div class="survey-popup" :class="{visible:visible}" v-if="enabled">
    <div class="survey-popup-heading" @click="visible=!visible">A Speedy Survey</div>
    <form class="survey-popup-body" @submit.prevent="submit">
        <p>Hey there! I have just one question for you: <b>What do you think of Speedy?</b>
        </p>
        <p>Your response goes to Tom, who will probably use it for improvements and/or
            bragging rights. He has a thick skin, so go nuts with criticism.</p>
        <p>It is anonymous, unless you put your name on it. Then it would not be
            anonymous anymore. I'm sorry, that's just how it works.</p>
        <textarea name="message"
        v-model="message"></textarea>
        <button class="button"@click="ignore">Ignore</button>
        <button class="button"type="submit">Send</button>
    </form>
</div>
</template>
<script>
export default {

    data() {
        return {
            visible: false,
            enabled: !localStorage['feedbackGiven'] && false,
            message: ''
        }
    },
    methods: {
        submit() {
            var data = new URLSearchParams();
            data.append('message', this.message);
            data.append('form-name', 'feedback');
            fetch('/feedbackform.html', { method: 'POST', body: data }).then(() => {
                alert("Thanks for the feedback!");
                localStorage['feedbackGiven'] = true;
                this.enabled = false;
            });
        },
        ignore() {
            alert("Don't worry - you won't be nagged again on this device!");
            localStorage['feedbackGiven'] = true;
            this.enabled = false;
        }
    }
}
</script>