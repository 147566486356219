import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";

//Publicly available: https://login.windows.net/pulteney.sa.edu.au/.well-known/openid-configuration
var PULTENEY_TENANT_ID = 'ebc0ee77-ff3b-4903-bce7-6cb50ce73fd4'

var msalConfig = {
    auth: {
        clientId: '3b87bd3f-2e6b-44f4-afba-39ebc4cd11b4',
        //The actual URL for redirectUri doesn't really matter.
        //But if it's not valid and you are hosting on SharePoint, it will not work.
        //So we just use a static file that is always available.
        redirectUri: location.origin + '/favicon/favicon.png',
        authority: "https://login.microsoftonline.com/organizations"
    },
    cache: {
        cacheLocation: "localStorage"
    },
};

var msalRequest = {
    scopes: ["user.read", "Files.Read.All", "User.ReadBasic.All", "Sites.Read.All", "offline_access"]
}

export const msalClient = new PublicClientApplication(msalConfig);
export async function signIn(logCallback) {
    if (!logCallback) logCallback = function () { }
    // Login
    try {
        // Use MSAL to login
        logCallback('Opening login window');
        const authResult = await msalClient.loginPopup(msalRequest);
        console.log('id_token acquired at: ' + new Date().toString());

        logCallback('Logged in to Office 365. Now to check that we can access the timetable...');
        console.log('Login successful!')
        if (location.pathname == '/login') {
            console.log('We are on the login page, about to verify that our token works')
            logCallback("The timetable works! Let's load up the Speedy web app.");
            var token = await getToken();
            return token;
        }
    } catch (error) {
        if (error.errorCode == 'user_cancelled') {
            logCallback('The login popup window was closed. Perhaps popups are blocked?', 'ERROR');
        } else {
            logCallback(error, 'ERROR');
        }
        console.error(error);
        throw error;
    }
}

async function getToken() {
    var account = getPGSAccount();

    if (!account) {
        throw new Error("No PGS account found.");
    }

    try {
        // First, attempt to get the token silently
        const silentRequest = {
            scopes: msalRequest.scopes,
            account: account
        };

        const silentResult = await msalClient.acquireTokenSilent(silentRequest);
        return silentResult.accessToken;
    } catch (silentError) {
        // If silent requests fails with InteractionRequiredAuthError,
        // attempt to get the token interactively
        if (silentError instanceof InteractionRequiredAuthError) {
            const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
            return interactiveResult.accessToken;
        } else {
            throw silentError;
        }
    }
}

function getPGSAccount() {
    const currentAccounts = msalClient.getAllAccounts();
    if (!currentAccounts || currentAccounts.length === 0) {
        // No user signed in
        console.log('No user signed in');
        return null;
    } else {
        var pgsAccount = currentAccounts.find(account =>
            account.tenantId === PULTENEY_TENANT_ID
        )
        if (!pgsAccount) {
            console.warn('User is logged into account(s), but none are PGS');
        }
        return pgsAccount;
    }
}

// Create an authentication provider
const authProvider = {
    getAccessToken: async () => {
        // Call getToken in auth.js
        return await getToken();
    }
};

// Initialize the Graph client
export const graphClient = MicrosoftGraph.Client.initWithMiddleware({ authProvider });
