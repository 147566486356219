<template>
    <div class="historical-timetables">
        <h1>Historical timetables</h1>
        <table>
            <tr v-for="timetable, idx in timetables"
                :class="{ 'historical-timetbles-active-row': timetable.lastModifiedDateTime === activeTimetableModified }">
                <td>{{ formatDate(timetable.lastModifiedDateTime) }}</td>
                <td>
                    <button class="button" @click="load(timetable)">Load</button>
                    <button class="button" @click="compare(idx)">Compare</button>
                </td>
            </tr>
        </table>
        <button class="button" @click="getTimetables()" v-if="nextLink">Load more...</button>
    </div>
</template>
<script>
import FileFetcher from '@/FileFetcher';
import loadEverything from '@/loadEverything';
import moment from 'moment';
import { graphClient } from '@/login';
import config from '@/config';
import router from '@/router';

function subtract(a, b) {
    return a.filter(aa => {
        var match = b.some(bb => JSON.stringify(aa) === JSON.stringify(bb));
        return !match;
    })
}

function xor(a, b) {
    return [...subtract(a, b), ...subtract(b, a)];
}

export default {

    data() {
        return {
            timetables: [],
            loading: false,
            nextLink: null
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('ddd D MMM YYYY h:mm A')
        },
        async getTimetables() {
            var graph_endpoint;
            if(this.nextLink) {
                graph_endpoint = this.nextLink;
            } else {
                graph_endpoint = `https://graph.microsoft.com/v1.0/drives/${config.SHAREPOINT_DRIVE_ID}/root:/Speedy/${config.SPEEDY_VERSION}:/children?$orderby=name desc`;
            
            }
            var folder = await graphClient
                .api(graph_endpoint)
                .get();
            var files = folder.value
            files = files.filter(file => file.name.match('.json$'));
            files.sort(sortBy(file => file.lastModifiedDateTime, { reverse: true }));

            if(folder['@odata.nextLink']) {
                this.nextLink = folder['@odata.nextLink'];
            } else {
                this.nextLink = null;
            }

            this.timetables.push(...files);
        },
        async load(latestFile) {
            this.loading = true;
            var downloadURL = latestFile['@microsoft.graph.downloadUrl'];
            var resp = await fetch(downloadURL);
            var timetable_data = await resp.json()
            this.timetable_data = timetable_data;
            FileFetcher.lastModifiedDateTime = latestFile.lastModifiedDateTime;
            loadEverything(this.timetable_data);
            var path = router.currentRoute.value.fullPath;
            await router.push('/');
            await router.push(path);
            this.loading = false;
        },
        async compare(idx) {
            this.loading = true;
            var downloadURL = this.timetables[idx]['@microsoft.graph.downloadUrl'];
            var resp = await fetch(downloadURL);
            var timetable_a = await resp.json();
            var downloadURL = this.timetables[idx+1]['@microsoft.graph.downloadUrl'];
            var resp = await fetch(downloadURL);
            var timetable_b = await resp.json();
            console.log('students',
                xor(
                    Object.values(timetable_a.students),
                    Object.values(timetable_b.students))
            );
            console.log('class_timeslots',
                xor(
                    Object.values(timetable_a.class_timeslots),
                    Object.values(timetable_b.class_timeslots))
            );
            console.log('teachers',
                xor(
                    Object.values(timetable_a.teachers),
                    Object.values(timetable_b.teachers))
            );
        },
    },
    computed: {
        activeTimetableModified() {
            return FileFetcher.lastModifiedDateTime;
        }
    },
    mounted() {
        this.getTimetables();
        document.body.style.marginRight = '400px';
    },
    unmounted() {
        document.body.style.marginRight = null;
    }
}
</script>
<style>
.historical-timetbles-active-row {
    background: #5252bb;
    color: white;
}

.historical-timetables {
    position: fixed;
    bottom: 0px;
    right: 0px;
    top: 0px;
    overflow-y: scroll;
    background: white;
    width: 400px;
}
</style>