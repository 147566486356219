import config from './config.js';
import {sortBy} from "./utility.js"
import moment from 'moment';
import timetable from '@/timetable.js';
import { graphClient } from './login';

export async function downloadLatestTimetable() {
    console.log('Downloading timetable data...');

    // Note: Maximum 200 items are returned: https://learn.microsoft.com/en-us/graph/api/driveitem-list-children?view=graph-rest-1.0&tabs=http#:~:text=Note%3A%20If%20a%20collection%20exceeds%20the%20default%20page%20size%20(200%20items)%2C
    // We cannot $orderby the date (only name is supported)
    // So $orderby name in descending order (so newest timetable is on top)
    // var graph_endpoint = 'users/' + config.USER_ID + '/drive/items/' + config.FOLDER_ID + ':/' + config.SPEEDY_VERSION + ':/children?$orderby=name desc';
    var graph_endpoint = `https://graph.microsoft.com/v1.0/drives/${config.SHAREPOINT_DRIVE_ID}/root:/Speedy/${config.SPEEDY_VERSION}:/children?$orderby=name desc`
    var folder = await graphClient
        .api(graph_endpoint)
        .get();

    var files = folder.value
    files = files.filter(file => file.name.match('.json$'));
    files.sort(sortBy(file => file.lastModifiedDateTime, { reverse: true }))
    var latestFile = files[0];
    var downloadURL = latestFile['@microsoft.graph.downloadUrl'];
    var resp = await fetch(downloadURL);
    var timetable_data = await resp.json()
    return { timetable_data: timetable_data, lastModifiedDateTime: latestFile.lastModifiedDateTime };
}


class Substitution {
    constructor(data) {
        Object.assign(this, data);
    }

    getTimeslot() {
        var matchingTimeslots = timetable.class_timeslots.filter(timeslot =>
            timeslot.period_short_name == parseInt(this.periodNumber) + 1  //Currently PowerAppsSubstitutions is 0-indexed, but Synergetic is 1-indexed
            && timeslot.cycle_day_index == this.timetableDate.day()
            && timeslot.class_code == this.classCode)
        if(matchingTimeslots.length == 0) {
            console.error("No timeslots for substitution lesson:", this);
            return null;
        }
        if(matchingTimeslots.length > 1) {
            console.error(`${matchingTimeslots.length} timeslots for substitution lesson:`, this);
        }
        
        return matchingTimeslots[0];
    }

    matchesTimeslot(timeslot) {
        return timeslot.period_short_name == parseInt(this.periodNumber) + 1  //Currently PowerAppsSubstitutions is 0-indexed, but Synergetic is 1-indexed
        && timeslot.cycle_day_index == this.timetableDate.day()
        && timeslot.class_code == this.classCode
    }
}

export async function downloadTodayPinkiesSharepoint() {
    console.log('Fetching substitution list...');
    var today = (new Date()).toISOString().split('T')[0];
    var graph_endpoint = config.SUBSTITUTION_LIST_URL;
    var response = await graphClient
        .api(graph_endpoint)
        .header('Prefer', 'HonorNonIndexedQueriesWarningMayFailRandomly')
        .expand('fields')
        .filter(`fields/field_TimetableDate ge '${today}'`)
        .orderby('fields/field_TimetableDate')
        .select('fields')
        .get();
        
    timetable.substitutions.splice(0, timetable.substitutions.length);
    response.value.forEach(substitutionItem => {
        var replacingTeacherName = substitutionItem.fields.field_ReplacingStaffEmail;
        var replacingTeacher = Object.values(timetable.teachers).find(t=>t.StaffOccupEmail == replacingTeacherName);
        var originalTeacherName = substitutionItem.fields.field_OriginalStaffEmail;
        var originalTeacher = Object.values(timetable.teachers).find(t=>t.StaffOccupEmail == originalTeacherName);
        var classCode = substitutionItem.fields.field_ClassCode;
        var periodNumber = substitutionItem.fields.field_PeriodNumber;
        var timetableDate = moment(substitutionItem.fields.field_TimetableDate);
        var substitution = new Substitution({
            replacingTeacher, originalTeacher, classCode, timetableDate, periodNumber
        })
        timetable.substitutions.push(substitution);
    });
}
