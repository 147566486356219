import { lessonTimes } from "./classes";

export function timeslotsPrettyPrint(timeslots) {
  var prevTimeSlotDay = null;
  var output = '';
  var seenSlots = [];
  var DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  timeslots = timeslots.slice()
  timeslots.sort((a, b) => a.cycle_day_index - b.cycle_day_index || a.period_short_name - b.period_short_name)
  timeslots.forEach(timeslot => {
    if (seenSlots.indexOf(timeslot) !== -1)
      return
    if (timeslot.cycle_day_index == prevTimeSlotDay) {
      output += '&';
    } else {
      output += ', ' + DAYS[timeslot.cycle_day_index - 1] + ' ';
    }
    var lessonTime = lessonTimes.find(lt => lt.synergy == timeslot.period_short_name)
    if (lessonTime.short_name == 'T')
      output += 'Tutor'
    else
      output += 'L' + lessonTime.short_name;
    prevTimeSlotDay = timeslot.cycle_day_index;
    seenSlots.push(timeslot);
  });
  output = output.substr(2);
  return output
}

//Source: https://stackoverflow.com/a/6274381
export function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export function closest(el, selector) {
  var matchesFn;
  ['matches',
    'webkitMatchesSelector',
    'mozMatchesSelector',
    'msMatchesSelector',
    'oMatchesSelector'].some(function (fn) {
      if (typeof document.body[fn] == 'function') {
        matchesFn = fn;
        return true;
      }
      return false;
    })

  var parent;

  // traverse parents
  while (el) {
    if (el && el[matchesFn](selector)) {
      return el;
    }
    el = el.parentElement;
  }

  return null;
}

export function throttle(func, wait) {
  var context, args, result;
  var timeout = null;
  var previous = 0;

  var later = function () {
    previous = +new Date();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = +new Date();
    if (!previous) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

export function sortBy(field, options) {
  options = options || {};
  options.insensitive = options.insensitive || false;
  return (a, b) => {
    var aField, bField;
    if (typeof field == 'function') {
      aField = field(a);
      bField = field(b);
    } else {
      aField = a[field];
      bField = b[field];
    }

    if (options.insensitive) {
      aField = aField.toLowerCase().trim();
      bField = bField.toLowerCase().trim();
    }
    if (options.trim) {
      aField = aField.trim();
      bField = bField.trim();
    }
    var result = (aField > bField) - (aField < bField)
    if (options.reverse)
      result = -result;
    return result;
  }
}
window.sortBy = sortBy;

export function hashCode(s) {
  var hash = 0;
  for (var i = 0; i < s.length; i++) {
    var character = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function deleteAllKeys(object) {
  if (object instanceof Array) {
    object.splice(0, object.length);
  }
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      delete object[key];
    }
  }
}

export function getLessonTimes(period, cycle_day_index) {
  if (typeof period == 'number') {
      return lessonTimes.find(lt => lt.synergy == period);
  }

  for (var i = 0; i < lessonTimes.length; i++) {
      var lt = lessonTimes[i];
      if (lt.short_name == period)
          return lessonTimes[i];
  }
  throw new Error("Period code not found");
}