<template>
    <div class="action-buttons footer" v-if="$route.name !== 'removeData'">
        <button class="button" @click="logOut()">Log out</button>
        <div class="footer-last-modified"><b>Last update:</b> {{ lastModified }}</div>
        <div class="footer-last-modified"><b>App version:</b> {{ BUILD_COMMIT }}</div>
        <survey-popup></survey-popup>
        <div v-if="error" class="footer-error" @click="relogin()"> <i class="fas fa-exclamation-triangle"></i>
            {{ error }}</div>
    </div>
</template>
<script>
import moment from 'moment';
import SurveyPopup from './SurveyPopup.vue';
import * as Sentry from "@sentry/browser";
import { msalClient } from '@/login';
import FileFetcher from '@/FileFetcher';

export default {
    data() {
        return {
            BUILD_COMMIT,
            FileFetcher
        };
    },
    components: { SurveyPopup },
    methods: {
        async logOut() {
            delete localStorage.speedy;
            Object.keys(localStorage).forEach(key => delete localStorage[key]);
            if (Sentry)
                Sentry.setUser(null);
            msalClient.logoutRedirect();
        },
        relogin() {
            delete localStorage.speedy;
            Object.keys(localStorage).forEach(key => delete localStorage[key]);
            location.href = '#/login';
        }
    },
    computed: {
        lastModified() {
            if (!this.FileFetcher.lastModifiedDateTime) {
                return 'N/A';
            } else {
                return moment(this.FileFetcher.lastModifiedDateTime).calendar({
                    sameElse: 'YYYY-MM-DD'
                });
            }
        },
        error() {
            //don't show an error telling the user to log in,
            //if they are are already on the login page
            if (this.$route.name === 'login')
                return null;
            var app = this.$root;
            return app.error;
        }
    }
}
</script>