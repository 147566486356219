import { reactive } from "vue";
import { deleteAllKeys } from "./utility";

const timetable = reactive({
    students: {},
    teachers: {},
    rooms: {},
    class_timeslots: [],
    subjects: {},
    heads_of_house: {},
    substitutions: [],
    canvas_exceptions: [],
    empty() {
        deleteAllKeys(timetable.students);
        deleteAllKeys(timetable.teachers);
        deleteAllKeys(timetable.rooms);
        deleteAllKeys(timetable.class_timeslots);
        deleteAllKeys(timetable.subjects);
        deleteAllKeys(timetable.heads_of_house);
        deleteAllKeys(timetable.substitutions);
        deleteAllKeys(timetable.canvas_exceptions);
    }
});
window.timetable = timetable;
export default timetable;