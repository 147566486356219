<template>
    <div>
        <div class="search-field-wrapper">
            <input id="search" type="text" name="search" autocomplete="off" :value="searchText"
                placeholder="Search for a student, teacher or room..." @keyup="onChange($event)" ref="search" />
            <template v-if="enableFilter">
                <select class="filter-select" :value="filterYear" @change="setFilter($event, 'year')">
                    <option value="">All years</option>
                    <option value="7">Year 7</option>
                    <option value="8">Year 8</option>
                    <option value="9">Year 9</option>
                    <option value="10">Year 10</option>
                    <option value="11">Year 11</option>
                    <option value="12">Year 12</option>
                </select>
                <select class="filter-select" :value="filterHouse" @change="setFilter($event, 'house')">
                    <option value="">All houses</option>
                    <option value="bh">Bleby-Howard</option>
                    <option value="cn">Cawthorne-Nicholls</option>
                    <option value="km">Kennion-Miller</option>
                    <option value="ms">Moore-Sunter</option>
                </select>
                <select class="filter-select" :value="filterType" @change="setFilter($event, 'type')">
                    <option value="">All</option>
                    <option value="student">Students</option>
                    <option value="teacher">Teachers</option>
                    <option value="room">Rooms</option>
                    <option value="subject">Subjects</option>
                </select>
                <button class="button" @click="studentList" v-show="showStudentList">Student list</button>
            </template>
        </div>
        <div class="search-logo" ref="search-logo" :class="{ hidden: !showLogo }"> <i class="fas fa-search"></i>
            <div>
                <p>Welcome to the super-speedy-gonzales-search.</p>
                <p>Type something above.</p>
            </div>
        </div>
    </div>
</template>
<script>
import { throttle } from '@/utility.js';
import globals from '@/globals';
import router from '@/router';

export default {
    data() {
        return {
            oldValue: '',
            globals
        }
    },
    methods: {
        onChangeThrottled: throttle(function ($event) {
            var searchText = $event.target.value;
            if (searchText != this.oldValue) {
                if (searchText.length == 0)
                    this.$router.push('/');
                else
                    this.$router.push({ name: 'search', params: { searchText: searchText } });
            }
            this.oldValue = searchText;
        }, 200),
        onChange($event) {
            this.onChangeThrottled($event);
            if ($event.keyCode == 13)
                $event.preventDefault();
        },
        setFilter(event, filterName) {
            var filterValue = event.target.value;


            var parts = this.searchText.split(' ')
            if (filterValue) {
                var needToAddFilter = true;
                parts = parts.map(part => {
                    if (part.indexOf(filterName + ':') !== -1) {
                        needToAddFilter = false;
                        return filterName + ':' + filterValue;
                    }
                    return part;
                });
                if (needToAddFilter) {
                    parts.push(filterName + ':' + filterValue);
                }
            } else {
                //"All" option selected -- remove the filter from the search input box
                parts = parts.filter(p => p.indexOf(filterName + ':') == -1)
            }

            var searchText = parts.join(' ');
            this.$router.push({ name: 'search', params: { searchText: searchText } });
            this.$emit('update:searchText', searchText);
        },
        studentList() {
            app.$emit('open-student-list');
        }
    },
    computed: {
        searchText() {
            return this.$route.params.searchText || '';
        },
        showLogo() {
            if (this.$route.name) {
                if (this.$route.name == 'search' && this.searchText.length == 0)
                    return true;
                if (this.$route.name == 'home')
                    return true;
            }
            return false;
        },
        filterYear() {
            var year = null;
            this.searchText.split(' ').forEach(part => {
                if (part.indexOf('year:') !== -1) {
                    year = part.replace('year:', '')
                }
            });
            return year || '';
        },
        filterHouse() {
            var house = null;
            this.searchText.split(' ').forEach(part => {
                if (part.indexOf('house:') !== -1) {
                    house = part.replace('house:', '')
                }
            });
            return house || '';
        },
        filterType() {
            var type = null;
            this.searchText.split(' ').forEach(part => {
                if (part.indexOf('type:') !== -1) {
                    type = part.replace('type:', '')
                }
            });
            return type || '';
        },
        showStudentList() {
            return globals.showStudentList;
        },
        enableFilter() {
            return !!localStorage['filter'];
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler($route, from) {
                if ($route.name == 'search')
                    this.$emit('update:searchText', $route.params.searchText);
                else
                    this.$emit('update:searchText', '');
            }
        }
    },
    mounted() {
        this.$refs.search.focus();
    }
}
</script>